//@import url('https://fonts.googleapis.com/css?family=Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&display=swap&subset=cyrillic,cyrillic-ext,latin-ext,vietnamese');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');
@import 'bootstrap/_variables';

@import 'layouts/_browselayout';
//Screens
@import 'screens/_login';
@import 'screens/_dashboard';
@import 'screens/_map';
@import 'screens/_project';
@import 'screens/_settings';
@import 'screens/_kataszter';

//Components

//Ui Elements
@import 'ui-elements/_input';
@import 'ui-elements/_button';
@import 'ui-elements/_checkbox';
@import 'ui-elements/_sidebar';
@import 'ui-elements/_navbar';
@import 'ui-elements/_box';
@import 'ui-elements/_widget';
@import 'ui-elements/_notification';
@import 'ui-elements/_search';
@import 'ui-elements/_floatbox';
@import 'ui-elements/_btn-group';
@import 'ui-elements/_slider';
@import 'ui-elements/_range-slider';
@import 'ui-elements/_details';
@import 'ui-elements/_draggables';
@import 'ui-elements/_subtasks';
@import 'ui-elements/_draw-button';
@import 'ui-elements/_expanded-widgets-override';
@import 'ui-elements/_loading';
@import 'ui-elements/_text';
@import 'ui-elements/gantt-modal';
@import 'ui-elements/_header';
@import 'ui-elements/filter-container';
@import 'ui-elements/comment-section';
@import 'ui-elements/charts';
@import 'ui-elements/modal';
@import 'ui-elements/draganddrop';
@import 'ui-elements/cadastre';
@import 'ui-elements/profile';
@import 'ui-elements/owlet_map';
@import 'ui-elements/map-interactions';
@import 'ui-elements/map-config-sidebar';
@import 'ui-elements/wifi-table';

@import '~bootstrap/scss/bootstrap';
@import '~leaflet/dist/leaflet.css';

body {
	font-family: 'Montserrat', sans-serif;
}

body, html, #root {
	height: 100%;
	background-color: $basic-background-color;
}


@media (min-width: 1676px) {
	.col-xxl-3 {
		flex: 0 0 25%;
		max-width: 25%;
	}

}

// Sticky data table header fix
.page-content.full {
	.browse-layout {
		overflow: visible;
	}
}

.fullwidth-select {
	width: 100% !important;
}