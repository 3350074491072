.alert-delete-icon {
  svg:hover {
    color: grey;
  }
  svg {
    margin-right: 10px;
  }
}

.alert-delete-confirm {
  text-align: center;
  font-size: 13px;
  background-color: #e2e2e2;
  padding: 10px 10px 10px 20px;
  margin: 10px 5% 10px 5%;
  border-radius: 10px;
  height: 70px;

  button {
    text-align: center;
    border-radius: 5px;
    border: 0;
    box-shadow: none;
    color: white;
    font-weight: 400;
    text-shadow: none;
    font-size: 14px;
    font-family: "Montserrat", sans-serif;
    background-color: grey;
    margin-top: 10px;
    outline: none;
    min-width: none;
    margin: 5px;
    padding: 2px 15px 2px 10px;
  }

  .yes {
    background-color: #7dd173;
  }

  .yes:hover {
    color: #7dd173;
    background-color: white;
  }

  .no {
    background-color: #d17373;
  }
  .no:hover {
    color: #d17373;
    background-color: white;
  }
}

.current-alert {
  margin-bottom: 10px;

  background-color: rgb(241, 241, 241);
  padding: 5px;
  border-radius: 10px;

  p {
    margin-left: 15px;
  }
}

.profile-modal {
  width: 60% !important;
  max-width: none !important;

  @media screen and (max-width: 767px) {
    width: 92% !important;
  }

  .modal-body {
    border-radius: 10px;
    padding: 0 15px 0px 15px;
  }

  .closebutton {
    border-radius: 5px;
    color: black;
    cursor: pointer;
    position: absolute;
    right: 5px;
    top: 5px;
    z-index: 10;
  }

  .profile-header {
    height: 120px;
    margin-bottom: 100px;
    background-color: #99d7d8;
    padding: 15px 0;

    @media screen and (max-width: 479px) {
      margin-bottom: 200px;
    }

    .mqtt-status {
      position: absolute;
      left: 10px;
      top: 110px;
      color: #212529;
      font-size: 13px;

      @media screen and (max-width: 479px) {
        top: 233px;
        left: auto;
        right: 20%;
      }
    }

    .notification-popup {
      width: 350px;
      background-color: white;
      position: absolute;
      right: -94px;
      top: 80px;
      z-index: 1000;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
      padding-bottom: 50px;

      @media screen and (max-width: 767px) {
        right: 0;
      }
      @media screen and (max-width: 767px) {
        width: 100%;
        height: 100vh;
        right: 0;
        top: -15px;
      }

      .close-button {
        color: #212529;
        cursor: pointer;
        position: absolute;
        right: 5px;
        top: 5px;
      }

      .title {
        color: #212529;
        p {
          font-size: 24px;
          text-align: center;
          padding: 10px;
          padding-top: 20px;
          font-weight: 500;
          margin-bottom: 0;
        }
      }

      .notify-body {
        overflow: auto;
        max-height: 400px;
        padding: 10px;

        .alert-list-element {
          margin: 5px;
          padding: 5px;
          background-color: aliceblue;
          border-radius: 5px;
        }

        .alert-desc {
          color: rgb(167, 167, 167);
          font-size: 15px;
          margin: 0;
        }

        .alert-date {
          font-size: 12px;
          margin-bottom: 8px;
        }
        p {
          margin: 0;
        }
      }
    }
    .header-button-group {
      height: 100%;

      .notifications {
        height: 55px;
        background-color: #212529;
        border-radius: 5px;
        padding: 10px;
        border: none;
        margin-right: 40px;

        @media screen and (max-width: 479px) {
          position: absolute;
          bottom: -180px;
          left: 30%;
          transform: translate(-50%);
        }

        svg {
          color: #99d7d8;

          @media screen and (max-width: 479px) {
            color: white;
          }
        }
      }

      .notifications:hover {
        svg {
          color: white;
        }
      }
      button {
        float: right;
      }
    }

    .user-img {
      margin: auto;
      width: 200px;
      height: 200px;
      border-radius: 50%;
      overflow: hidden;
      border: 4px solid #f2fdfd;
      position: absolute;
      left: 50%;
      transform: translate(-50%);
      top: -90px;

      img {
        height: 100%;
        width: auto;
        object-fit: fill;
      }
    }
  }

  .profile-body {
    background-color: #ffffff;
    padding-bottom: 50px;
    overflow: auto;

    .col-lg-6 {
      padding: 10px;
      min-height: 310px;

      @media screen and (max-width: 991px) {
        min-height: auto;
      }

      @media screen and (max-width: 479px) {
        margin-bottom: 15px;
      }
    }

    .message-container {
      overflow-y: auto;
      overflow-x: hidden;

      h4{
        @media screen and (max-width: 767px) {
            font-size: 22px;
            margin-bottom: 15px;
        }
      }
    }

    .edit-profile {
      .form-group {
        @media screen and (max-width: 479px) {
          flex-direction: column;
          margin-bottom: 0;
        }
      }
    }

    .profil-edit-button-group {
      max-width: 347px;
      margin-left: auto;
      padding-right: 15px;

      @media screen and (max-width: 479px) {
        margin-top: 15px;
      }

      button {
        width: 49%;

        &:last-child {
          margin-left: 5px;
        }
      }

      .save {
        background-color: #7ace71;
        border-radius: 10px;
      }

      .cancel {
        background-color: #d17373;
        border-radius: 10px;
        color: white;
      }
    }

    .form-group {
      display: flex;

      .form-label {
        width: 275px;
        padding-top: 15px;
      }
    }

    .settings-container {
      margin-top: 20px;
    }

    .comment-container {
      margin-top: 20px;
    }

    .card-header {
      border-bottom: none;
      text-align: center;
      font-size: 20px;
      font-weight: 500;
    }

    .edit-icon {
      float: right;
    }

    .edit-icon:hover {
      color: #212529;
    }

    .user-data {
      margin-bottom: 30px;
      .user-name {
        font-size: 29px;
        font-weight: 500;

        @media screen and (max-width: 767px) {
            font-size: 24px;
            margin-bottom: 10px;
        }
      }

      .edit-icon {
        svg:hover {
          color: #7ace71;
        }
        padding-top: 5px;
      }
    }
  }
}

.alert-message-profile {
  background-color: red;
  position: absolute;
  right: 55px;
  padding: 2px 8px 2px 8px;
  border-radius: 10px;
  color: white;
  top: 40px;
  font-size: 10px;
}
