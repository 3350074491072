
body {
	font-family: 'Montserrat', sans-serif;
}

body, html, #root {
	height: 100%;
	background-color: $basic-background-color;
}

.page-content {
	//margin-top: 24px;
	//max-width: 1100px;
	//margin-left: 230px;
	margin-left: 60px;
	transition: 0.3s margin ease-in;
	padding-top: 80px;
	position: relative;

	@media screen and (max-width: 767px) {
		margin-left: 0;
	}

	&.full {
		max-width: inherit !important;
	}

	&.closed {
		margin-left: 60px;
		transition: 0.2s margin ease-out;
		transition-delay: 0.1s;

		@media screen and (max-width: 767px) {
			margin-left: 0;
		}
	}

	&:not(.map):not(.full) {
		background-image: url('/HUN_bekescsaba_cimer.png');
		background-size: 700px;
		background-position: top 20px right 10%;
		min-height: calc(100vh);
		background-repeat: no-repeat;
		background-attachment: fixed;
	}
}


